<template>
  <v-card flat>
    <v-toolbar flat color="fill">
      <ToggleExpanded :refs="$refs"></ToggleExpanded>
      <v-divider vertical inset class="mx-4" />
      <v-col cols="3" class="pl-0">
        <v-text-field
          :label="filter ? 'Press Enter to Search' : 'Search Recap'"
          prepend-inner-icon="mdi-magnify"
          outlined
          dense
          background-color="input"
          v-model="filter"
          clearable
          hide-details>
        </v-text-field>
      </v-col>
    </v-toolbar>
    <v-data-table
      :height="tableSize"
      :loading="loading"
      :headers="headersReversal"
      :expanded="expanded"
      :items.sync="reversalRecap"
      :items-per-page.sync="pageSize"
      color="#fff" loading-text="Loading... Please wait"
      fixed-header
      dense
      :search="filter"> 
    </v-data-table>
  </v-card>
</template>
<script>
// Components
import ToggleExpanded from '@/components/ToggleExpanded.vue'
// Mixins
import { fullWidth } from '@/mixins/fullWidth'
import { displayAlert } from '@/mixins/displayAlert'
export default {
  name: 'reversal-table',
  data: () => ({
    expanded: [],
    filter: '',
    loading: false,
    search:'',
    reversalRecap: [],
  }),
  components: { ToggleExpanded },
  mixins: [fullWidth, displayAlert],
  props: ['panel_height', 'selectedBatch'],
  watch: {
    filter: {
      handler () {
        if (this.filter === null) {
          this.filter = ''
        }
      }
    },
    panel_height: {
      handler (value) {
        this.nonTableHeight = value
      } 
    }
  },
  computed: {
    headersReversal() {
      return [
        { text: 'Store Name', align: 'left', sortable: true, filterable: true, value: 'store_name_num', class: 'black--text' },
        { text: 'Reversal Info', align: 'left', sortable: true, filterable: true, value: 'total_allowance_from_store', class: 'black--text' },
      ]
    }
  },
  async created () {
    this.nonTableHeight = this.panel_height
    await this.getReversalRecap()
  },
  methods: {
    async getReversalRecap() {
      this.loading = true
      try {
        const res = await this.$BillingBatch.getReversalRecap(this.selectedBatch.id)
        this.reversalRecap = res?.data || []
      } catch (err) {
        this.handleError(err)
      } finally {
        this.loading = false
      }
    }
  }
}
</script>
